import { UploadedDocumentType } from "@gocardless/api/dashboard/types";
import {
  Box,
  Button,
  ButtonVariant,
  ColorPreset,
  Form,
  H4,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useForm } from "react-hook-form";

import { HavingTroubleDialog } from "../../common/HavingTroubleDialog";
import { AcceptedDocuments } from "../../common/AcceptedDocuments";
import { useUploadDocuments } from "../../required-documents/useUploadDocuments";
import { RequiredActionCardProps } from "../../required-actions/RequiredActionCard";
import FormError from "../../common/FormError";
import { UploadDocOnlyFormValues } from "../../constants/DocumentRequirement";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { ControlledFileUpload } from "../../common/ControlledFileUpload";

import { CompanyDocumentsRequirements } from "./CompanyDocumentsRequirements";

import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { CountryCodes } from "src/common/country";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

export const CompanyDocumentUpload: React.FC<RequiredActionCardProps> = ({
  creditorId,
  verificationId,
  acceptedDocuments,
  documentRequirements,
  creditorGeo,
}) => {
  const { uploading, uploadDocuments } = useUploadDocuments(
    creditorId,
    verificationId
  );

  const { validateFilesMaxOnly } = useUploadDocValidation();
  const { sendEvent } = useSegment();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UploadDocOnlyFormValues>({
    defaultValues: {
      upload_docs: [],
    },
  });

  const uploadSelectedDocuments = async (value: UploadDocOnlyFormValues) => {
    await uploadDocuments(
      value.upload_docs,
      UploadedDocumentType.IncorporationDocuments
    );
  };

  const { isVariationOn: isKbisUploadsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.VERIFICATIONS_IMPROVE_KBIS_UPLOADS,
  });

  const isFrenchMerchant = creditorGeo === CountryCodes.FR;

  return (
    <Form onSubmit={handleSubmit(uploadSelectedDocuments)}>
      <AcceptedDocuments multiple documents={acceptedDocuments} />

      {documentRequirements && (
        <Box
          spaceAbove={1}
          gutterV={1.5}
          gutterH={1.5}
          borderColor={ColorPreset.BorderOnLight_04}
          borderRadius={1}
          borderWidth={1}
        >
          <H4 preset={TypePreset.Heading_01}>
            <Trans>The document provided must clearly show:</Trans>
          </H4>

          <Box spaceAbove={1}>
            <CompanyDocumentsRequirements
              requirements={documentRequirements}
              isKbisUploadsEnabled={isKbisUploadsEnabled}
              isFrenchMerchant={isFrenchMerchant}
            />
          </Box>
        </Box>
      )}

      <Box spaceAbove={1}>
        <ControlledFileUpload
          required
          name="upload_docs"
          control={control}
          uploading={uploading}
          validationRules={validateFilesMaxOnly}
        />
      </Box>
      {errors.upload_docs?.message ? (
        <FormError errorMessage={errors.upload_docs?.message} />
      ) : null}
      <Box spaceAbove={2}>
        <Button
          type="submit"
          variant={ButtonVariant.PrimaryOnLight}
          disabled={uploading}
          onClick={() =>
            isKbisUploadsEnabled &&
            isFrenchMerchant &&
            sendEvent(TrackingEvent.VERIFICATION_KBIS_DOCUMENT_UPLOADED)
          }
        >
          <Trans>Upload</Trans>
        </Button>
      </Box>
      <HavingTroubleDialog sourceComponent={CompanyDocumentUpload.name} />
    </Form>
  );
};

import { UploadedDocumentType } from "@gocardless/api/dashboard/types";
import { Box, ColorPreset, Field, Form } from "@gocardless/flux-react";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLingui } from "@lingui/react";

import { AcceptedDocuments } from "../../common/AcceptedDocuments";
import { HavingTroubleDialog } from "../../common/HavingTroubleDialog";
import { OnfidoSupportedCountryCodes } from "../../constants/OnfidoSupportedCountries";
import { useUploadDocuments } from "../../required-documents/useUploadDocuments";
import { RequiredActionCardProps } from "../RequiredActionCard";
import FormError from "../../common/FormError";
import useHasSubmitted from "../../hooks/useHasSubmitted";
import { IdentityFormValues } from "../../constants/DocumentRequirement";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { ControlledCheckBox } from "../../common/ControlledCheckBox";

import { ManualDocumentUpload } from "./ManualDocumentUpload";
import { TriggerOnfidoFlow } from "./TriggerOnfidoFlow";

import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { CountryCodes } from "src/common/country";

export const IdentityDocumentUpload: React.FC<RequiredActionCardProps> = ({
  creditorId,
  verificationId,
  acceptedDocuments,
  updateRequiredDocuments,
  entity,
  setMutationFetchingPaused,
}) => {
  const { hasSubmitted, handleOnError, resetHasSubmitted } = useHasSubmitted();
  const [showOnfidoModal, setShowOnfidoModal] = useState<boolean>(false);
  const [shouldRenderOnfidoUpload, setShouldRenderOnfidoUpload] =
    useState<boolean>(false);
  const { sendEvent } = useSegment();
  const { i18n } = useLingui();
  const { uploading, uploadDocuments } = useUploadDocuments(
    creditorId,
    verificationId
  );
  const {
    uploadDocValidationMessages: { confirmNoPhotoCopy },
  } = useUploadDocValidation();

  const methods = useForm<IdentityFormValues>({
    defaultValues: {
      identity_confirmation: false,
      upload_docs: [],
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const entityCountryCode = entity["country_code"];

  const uploadIdentityDocuments = async (values: IdentityFormValues) => {
    if (shouldRenderOnfidoUpload) {
      sendEvent(
        TrackingEvent.VERIFICATION_ADDITIONAL_DETAILS_ONFIDO_ID_CHECK_STARTED
      );
      setShowOnfidoModal(true);
      if (setMutationFetchingPaused) {
        setMutationFetchingPaused(true);
      }
    } else {
      await uploadDocuments(
        values.upload_docs,
        UploadedDocumentType.NationalIdentityCard
      );
    }
  };

  useEffect(() => {
    const onfidoSupportsCountryCode = entityCountryCode
      ? OnfidoSupportedCountryCodes.includes(entityCountryCode as CountryCodes)
      : false;
    if (onfidoSupportsCountryCode) {
      setShouldRenderOnfidoUpload(true);
    }
  }, [entityCountryCode]);

  return (
    <Box spaceAbove={0.25}>
      <AcceptedDocuments bothSides={true} documents={acceptedDocuments} />
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(uploadIdentityDocuments, handleOnError)}>
          <Box spaceBelow={1} spaceAbove={1}>
            <Field>
              <Box
                spaceAbove={0.25}
                gutterV={1.5}
                gutterH={1.5}
                borderColor={ColorPreset.BorderOnLight_04}
                borderRadius={1}
                borderWidth={1}
              >
                <ControlledCheckBox
                  name="identity_confirmation"
                  control={control}
                  labelText={i18n._(
                    t`I confirm that the proof of identity cannot be a photocopy.`
                  )}
                  description={i18n._(
                    t`Invalid proof will prevent us from processing any payments.`
                  )}
                  required={confirmNoPhotoCopy}
                  resetHasSubmitted={resetHasSubmitted}
                />
              </Box>
            </Field>
          </Box>
          {errors.identity_confirmation?.message && hasSubmitted ? (
            <FormError errorMessage={errors.identity_confirmation.message} />
          ) : null}

          {shouldRenderOnfidoUpload ? (
            <TriggerOnfidoFlow
              creditorId={creditorId}
              verificationId={verificationId}
              showOnfidoModal={showOnfidoModal}
              updateRequiredDocuments={updateRequiredDocuments}
              setShowOnfidoModal={setShowOnfidoModal}
              setShouldRenderOnfidoUpload={setShouldRenderOnfidoUpload}
              setMutationFetchingPaused={setMutationFetchingPaused}
            />
          ) : (
            <ManualDocumentUpload uploading={uploading} />
          )}
        </Form>
      </FormProvider>
      <HavingTroubleDialog sourceComponent={IdentityDocumentUpload.name} />
    </Box>
  );
};

import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";

import {
  addBusinessDays,
  isValidDate,
} from "src/components/routes/Imports/ImportsBulkChange/BulkChangeRequest/utils";

const MAXIMUM_EXPECTED_DAYS_TO_REVIEW = 3;

export const verificationIsTakingLongerThanMaximumExpected = (
  lastTransitionDate: Date
): boolean => {
  const lastTransitionCalendarDate = new CalendarDate(
    lastTransitionDate.getFullYear(),
    lastTransitionDate.getMonth() + 1, // getMonth returns 0-indexed month
    lastTransitionDate.getDate()
  );

  if (!isValidDate(lastTransitionCalendarDate)) {
    return false;
  }

  const maxExpectedTransitionDate = addBusinessDays(
    lastTransitionCalendarDate,
    MAXIMUM_EXPECTED_DAYS_TO_REVIEW
  );

  const dateToday = today(getLocalTimeZone());

  return dateToday >= maxExpectedTransitionDate;
};

import {
  CreditorType,
  RequiredActionsStatus,
} from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";

import { HighRiskDocumentUpload } from "src/components/routes/Setup/verification-status/required-actions/high-risk/HighRiskDocumentUpload";
import { RequiredAction } from "src/components/routes/Setup/verification-status/required-actions/RequiredAction";
import { RequiredActionCardProps } from "src/components/routes/Setup/verification-status/required-actions/RequiredActionCard";

export const HighRiskVerificationCard = (props: RequiredActionCardProps) => {
  const { creditorType, actionStatus, notificationEmail: nEmail } = props;
  const notificationEmail = <Text className="fs-mask">{nEmail}</Text>;

  switch (actionStatus) {
    case RequiredActionsStatus.Pending:
      return (
        <RequiredAction
          header={
            creditorType === CreditorType.Individual ? (
              <Trans>Confirm financial position</Trans>
            ) : creditorType === CreditorType.Charity ? (
              <Trans>Confirm beneficial owners & financial position</Trans>
            ) : (
              <Trans>Confirm business owners & financial position</Trans>
            )
          }
          description={
            <Trans>
              We require additional documentation from your business:
            </Trans>
          }
          body={<HighRiskDocumentUpload {...props} />}
        />
      );
    case RequiredActionsStatus.PendingResponse:
      return (
        <RequiredAction
          header={
            creditorType === CreditorType.Individual ? (
              <Trans>Confirm financial position</Trans>
            ) : creditorType === CreditorType.Charity ? (
              <Trans>Confirm beneficial owners & financial position</Trans>
            ) : (
              <Trans>Confirm business owners & financial position</Trans>
            )
          }
          description={
            <Trans>
              We&apos;ve received your document and our verification team has
              reached out to you at <b>{notificationEmail}</b> for further
              information.
            </Trans>
          }
        />
      );
    default:
      return null;
  }
};

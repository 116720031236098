import {
  Box,
  ColorPreset,
  H4,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { PartnershipType } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";

import { AcceptedDocuments } from "../../common/AcceptedDocuments";
import { DocumentRequirement } from "../../constants/DocumentRequirement";
import { ACCEPTED_DOCUMENTS_COUNTRY_MAP } from "../../config/PartnersConfirmationConfig";

import { GermanPartnersDocumentRequirements } from "./GermanPartnersDocumentRequirements";

import { CountryCodes } from "src/common/country";

export const GermanPartnershipDocumentRequirements = ({
  requirements,
}: {
  requirements: DocumentRequirement[];
  creditorSubtype: PartnershipType;
}) => (
  <Box spaceAbove={2}>
    <Text
      spaceBelow={0.5}
      preset={TypePreset.Body_01}
      color={ColorPreset.TextOnLight_01}
    >
      <Trans>
        If one or more partners is a legal entity, you will{" "}
        <strong>need</strong> to upload a current Handelsregister extract for
        each legal entity partner.
      </Trans>
    </Text>
    <Space v={1} />
    <AcceptedDocuments
      multiple
      documents={ACCEPTED_DOCUMENTS_COUNTRY_MAP[CountryCodes.DE] || []}
    />
    {requirements && (
      <Box
        spaceAbove={1}
        gutterV={1.5}
        gutterH={1.5}
        borderColor={ColorPreset.BorderOnLight_04}
        borderRadius={1}
        borderWidth={1}
      >
        <H4 preset={TypePreset.Heading_01}>
          <Trans>
            The current extract(s) <strong>must</strong> confirm the following:
          </Trans>
        </H4>

        <Box spaceAbove={1}>
          <GermanPartnersDocumentRequirements requirements={requirements} />
        </Box>
      </Box>
    )}
  </Box>
);

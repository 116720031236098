import {
  Box,
  ColorPreset,
  FontWeight,
  H4,
  LI,
  TypePreset,
  UL,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import {
  AcceptedDocuments as AcceptedDocument,
  ACCEPTED_DOCUMENTS_GUIDANCE_COMPONENT_MAP,
  AcceptedDocumentsNameMap,
  AcceptedDocumentsWithMultipleSides,
} from "../../common/constants/AcceptedDocuments";

export const AcceptedDocuments = ({
  documents,
  multiple = false,
  bothSides = false,
  caller,
}: {
  documents: AcceptedDocument[];
  multiple?: boolean;
  bothSides?: boolean;
  caller?: string;
}) => {
  const { i18n } = useLingui();
  const ACCEPTED_DOCUMENTS_NAME_MAP = AcceptedDocumentsNameMap(i18n);

  return (
    <Box>
      <H4
        spaceBelow={0.5}
        preset={TypePreset.Body_01}
        color={ColorPreset.TextOnLight_01}
        weight={FontWeight.Normal}
      >
        {multiple ? (
          <Trans>What we can accept:</Trans>
        ) : (
          <Trans>Please upload one of the following documents as proof:</Trans>
        )}
      </H4>
      <UL preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_01}>
        {documents.map((item) => {
          const documentName = ACCEPTED_DOCUMENTS_NAME_MAP[item];
          const DocumentGuidanceComponent =
            ACCEPTED_DOCUMENTS_GUIDANCE_COMPONENT_MAP[item];
          const renderedDocumentGuidance = DocumentGuidanceComponent ? (
            <>
              {" "}
              <DocumentGuidanceComponent />
            </>
          ) : null;

          const isAPreferredDocument =
            item === AcceptedDocument.BankAccountStatement;

          return bothSides &&
            AcceptedDocumentsWithMultipleSides.includes(item) ? (
            <LI key={item}>
              <Trans>
                <b>Front and back</b> of a
              </Trans>{" "}
              {documentName}
              {renderedDocumentGuidance}
            </LI>
          ) : (
            <LI key={item}>
              {isAPreferredDocument && caller === "bank_document_upload" ? (
                <>
                  {documentName}{" "}
                  <Trans>
                    <b>({"preferred"})</b>
                  </Trans>
                </>
              ) : (
                <>
                  {documentName}
                  {renderedDocumentGuidance}
                </>
              )}
            </LI>
          );
        })}
      </UL>
    </Box>
  );
};

import { AcceptedDocuments } from "../../common/constants/AcceptedDocuments";
import { PartnersConfirmationStatus } from "../status-cards/status-item/PartnersConfirmationStatus";
import { PartnersConfirmationCard } from "../required-actions/partners-confirmation/PartnersConfirmationCard";

import { CountryCodes } from "src/common/country";

export const ACCEPTED_DOCUMENTS_COUNTRY_MAP: Record<
  string,
  AcceptedDocuments[]
> = {
  [CountryCodes.AU]: [
    AcceptedDocuments.RecentAsicFilingWithOwnershipStructure,
    AcceptedDocuments.SignedTrustDeed,
  ],
  [CountryCodes.CA]: [
    AcceptedDocuments.RecentAnnualReportOrReturn,
    AcceptedDocuments.CertifiedOwnershipStructure,
  ],
  [CountryCodes.DE]: [AcceptedDocuments.CurrentPrintoutFromTheHandelsregister],
};

export const PartnersConfirmationConfig = {
  acceptedDocuments: (geo: CountryCodes) =>
    ACCEPTED_DOCUMENTS_COUNTRY_MAP[geo] || [],

  statusComponent: PartnersConfirmationStatus,

  actionComponent: PartnersConfirmationCard,
};

import { UploadedDocumentType } from "@gocardless/api/dashboard/types";
import { Box, Button, ButtonVariant, Form } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useForm } from "react-hook-form";

import { RequiredActionCardProps } from "../RequiredActionCard";
import { useUploadDocuments } from "../../required-documents/useUploadDocuments";
import { AcceptedDocuments } from "../../common/AcceptedDocuments";
import FormError from "../../common/FormError";
import { UploadDocOnlyFormValues } from "../../constants/DocumentRequirement";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { ControlledFileUpload } from "../../common/ControlledFileUpload";

import { HavingTroubleDialog } from "src/components/routes/Setup/verification-status/common/HavingTroubleDialog";

export const PartnersConfirmationUpload: React.FC<RequiredActionCardProps> = ({
  creditorId,
  verificationId,
  acceptedDocuments,
}) => {
  const { uploading, uploadDocuments } = useUploadDocuments(
    creditorId,
    verificationId
  );

  const { validateFilesMaxOnly } = useUploadDocValidation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UploadDocOnlyFormValues>({
    defaultValues: {
      upload_docs: [],
    },
  });

  const uploadSelectedDocuments = async (value: UploadDocOnlyFormValues) => {
    uploadDocuments(
      value.upload_docs,
      UploadedDocumentType.CertifiedCopyOfThePartnershipAgreement
    );
  };

  return (
    <Box>
      <Form onSubmit={handleSubmit(uploadSelectedDocuments)}>
        <AcceptedDocuments multiple documents={acceptedDocuments} />

        <Box spaceAbove={2}>
          <ControlledFileUpload
            required
            name="upload_docs"
            control={control}
            uploading={uploading}
            validationRules={validateFilesMaxOnly}
          />
        </Box>
        {errors.upload_docs?.message ? (
          <FormError errorMessage={errors.upload_docs?.message} />
        ) : null}
        <Box spaceAbove={2}>
          <Button
            type="submit"
            variant={ButtonVariant.PrimaryOnLight}
            disabled={uploading}
          >
            <Trans>Upload</Trans>
          </Button>
        </Box>
      </Form>
      <HavingTroubleDialog sourceComponent={PartnersConfirmationUpload.name} />
    </Box>
  );
};

import {
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Glyph,
  IconButton,
  Tooltip,
  TooltipPlacement,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useState } from "react";
import { isMobile } from "react-device-detect";

import { copyToClipboard } from "src/utils/copyToClipboard";

const GREYSTONE_1100 = "#6E685E";
const ULTRAVIOLET_700 = "#5949CB";

export const CopyButton: React.FC<{
  value?: string;
  label?: string;
  iconVariant?: "solid" | "outline";
  tooltipPlacement?: TooltipPlacement;
}> = ({
  value,
  label = <Trans>Copy</Trans>,
  tooltipPlacement = "top",
  iconVariant = "solid",
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const iconGlyph = iconVariant === "solid" ? Glyph.Copy : Glyph.CopyOutline;

  if (isMobile) {
    return (
      <IconButton
        icon={iconGlyph}
        data-testid="copy-button"
        size={{ base: ButtonSize.Md, gutters: ButtonGutter.Sm }}
        label={label}
        variant={ButtonVariant.TextOnLight}
        style={{ color: GREYSTONE_1100 }}
        onClick={() => {
          copyToClipboard(value);
        }}
      />
    );
  }

  return (
    <Tooltip
      placement={tooltipPlacement}
      message={isClicked ? <Trans>Copied!</Trans> : <Trans>Copy</Trans>}
      triggeredBy={isHover ? "hover" : "click"}
    >
      {(triggerProps) => (
        <IconButton
          key={isHover ? "hover" : "no-hover"}
          {...triggerProps}
          icon={iconGlyph}
          data-testid="copy-button"
          size={{ base: ButtonSize.Md, gutters: ButtonGutter.Sm }}
          label={label}
          variant={ButtonVariant.TextOnLight}
          style={{ color: isHover ? ULTRAVIOLET_700 : GREYSTONE_1100 }}
          onClick={() => {
            triggerProps.onClick && triggerProps.onClick();
            copyToClipboard(value);
            setIsClicked(true);
          }}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={(e) => {
            (e.currentTarget as HTMLElement).blur(); // removes focus from the button after click
            setIsHover(false);
            setTimeout(() => {
              setIsClicked(false);
            }, 800);
          }}
        />
      )}
    </Tooltip>
  );
};

import {
  Box,
  Color,
  ColorPreset,
  DD,
  DL,
  DLLayout,
  DT,
  FontWeight,
  Glyph,
  Icon,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { useLingui } from "@lingui/react";
import { Fragment, useMemo } from "react";
import { Trans } from "@lingui/macro";

import { useSetupData } from "../../../common/hooks/useSetupData";
import {
  BoldText,
  InfoRowContainer,
  InfoRowText,
} from "../bank-account-identity/BankVerificationDocumentsRequirements";
import {
  CreditorDetails,
  getCompanyDetails,
} from "../../helpers/getCreditorDetails";

import {
  DocumentRequirement,
  DocumentRequirementsNameMap,
} from "src/components/routes/Setup/verification-status/constants/DocumentRequirement";

const DOCUMENT_REQUIREMENT_MAPPING: {
  [key in DocumentRequirement]?: keyof CreditorDetails;
} = {
  [DocumentRequirement.LegalEntityName]: "name",
  [DocumentRequirement.RegistrationNumber]: "entity_number",
  [DocumentRequirement.RegisteredAddress]: "address",
  [DocumentRequirement.EinNumber]: "entity_number",
  [DocumentRequirement.SirenNumber]: "entity_number",
};

export const CompanyDocumentsRequirements = ({
  requirements,
  isKbisUploadsEnabled,
  isFrenchMerchant,
}: {
  requirements: DocumentRequirement[];
  isKbisUploadsEnabled: boolean;
  isFrenchMerchant: boolean;
}) => {
  const { i18n } = useLingui();
  const DOCUMENT_REQUIREMENTS_NAME_MAP = DocumentRequirementsNameMap(i18n);

  const { creditorDetails } = useSetupData();
  const months = 3;

  const requirementsWithValues = useMemo(() => {
    if (!creditorDetails?.detail) {
      return [];
    }

    const companyDetails = getCompanyDetails(creditorDetails.detail);

    return requirements.map((requirement) => {
      const mappingKey = DOCUMENT_REQUIREMENT_MAPPING[requirement];

      return {
        name: requirement,
        value: mappingKey && companyDetails[mappingKey],
      };
    });
  }, [creditorDetails, requirements]);

  return isKbisUploadsEnabled && isFrenchMerchant ? (
    <Box flexDirection="column" layout="flex" css={{ gap: "16px" }}>
      {requirementsWithValues.map(({ name, value }) => (
        <InfoRowContainer key={name}>
          <Icon name={Glyph.TickCircle} color={Color.Success_300} />
          <InfoRowText>
            {DOCUMENT_REQUIREMENTS_NAME_MAP[name]}:{" "}
            <Text
              className="fs-mask"
              weight={FontWeight.SemiBold}
              preset={TypePreset.Body_01}
              color={ColorPreset.TextOnLight_01}
            >
              {value}
            </Text>
          </InfoRowText>
        </InfoRowContainer>
      ))}
      <InfoRowContainer>
        <Icon name={Glyph.TickCircle} color={Color.Success_300} />
        <InfoRowText>
          <Trans>
            Dated within the last <BoldText>{`${months} months`}</BoldText>
          </Trans>
        </InfoRowText>
      </InfoRowContainer>
      <InfoRowContainer>
        <Icon name={Glyph.TickCircle} color={Color.Success_300} />
        <InfoRowText>
          {
            DOCUMENT_REQUIREMENTS_NAME_MAP[
              DocumentRequirement.LegalRepresentatives
            ]
          }
        </InfoRowText>
      </InfoRowContainer>
      <InfoRowContainer>
        <Icon name={Glyph.CloseCircle} color={Color.Alert_500} />
        <InfoRowText>
          <Trans>Not blurry or cropped</Trans>
        </InfoRowText>
      </InfoRowContainer>
    </Box>
  ) : (
    <DL layout={DLLayout.RowFixedTerm}>
      {requirementsWithValues.map(({ name, value }) => (
        <Fragment key={name}>
          <DT style={value ? {} : { gridColumn: "span 3" }}>
            <Text
              weight={FontWeight.Normal}
              preset={TypePreset.Body_01}
              color={ColorPreset.TextOnLight_01}
            >
              {DOCUMENT_REQUIREMENTS_NAME_MAP[name]}
            </Text>
          </DT>
          {value && (
            <DD>
              <Text
                weight={FontWeight.SemiBold}
                preset={TypePreset.Body_01}
                color={ColorPreset.TextOnLight_01}
                className="fs-mask"
              >
                {value}
              </Text>
            </DD>
          )}
        </Fragment>
      ))}
    </DL>
  );
};

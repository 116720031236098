import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";

import { getPersonName } from "../../helpers/getPersonName";
import { RequiredAction } from "../RequiredAction";
import { RequiredActionCardProps } from "../RequiredActionCard";

import { AddressDocumentUpload } from "./AddressDocumentUpload";

export const AddressConfirmationCard = (props: RequiredActionCardProps) => {
  const { actionStatus, entity, notificationEmail: nEmail } = props;
  const entityTitle = <Text className="fs-mask">{getPersonName(entity)}</Text>;
  const notificationEmail = <Text className="fs-mask">{nEmail}</Text>;

  switch (actionStatus) {
    case RequiredActionsStatus.Pending:
      return (
        <RequiredAction
          header={<Trans>Verify address details for {entityTitle}</Trans>}
          description={
            <Trans>
              Please upload a document as proof of your current address.
            </Trans>
          }
          body={<AddressDocumentUpload {...props} />}
        />
      );
    case RequiredActionsStatus.PendingResponse:
      return (
        <RequiredAction
          header={<Trans>Verify address details for {entityTitle}</Trans>}
          description={
            <Trans>
              We&apos;ve received your document and our verification team has
              reached out to you at <b>{notificationEmail}</b> for further
              information.
            </Trans>
          }
        />
      );
    default:
      return null;
  }
};

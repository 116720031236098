import {
  Box,
  Button,
  ButtonVariant,
  ColorPreset,
  Form,
  H4,
  TypePreset,
} from "@gocardless/flux-react";
import {
  PartnershipType,
  UploadedDocumentType,
} from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { useForm } from "react-hook-form";

import { useUploadDocuments } from "../../required-documents/useUploadDocuments";
import { AcceptedDocuments } from "../../common/AcceptedDocuments";
import { RequiredActionCardProps } from "../RequiredActionCard";
import { useSetupData } from "../../../common/hooks/useSetupData";
import {
  DE_PARTNERS_DOCUMENT_REQUIREMENTS_DEFAULT,
  getAcceptedDocuments,
  getDocumentRequirements,
} from "../../config/PartnershipIdentityConfig";
import FormError from "../../common/FormError";
import { UploadDocOnlyFormValues } from "../../constants/DocumentRequirement";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { ControlledFileUpload } from "../../common/ControlledFileUpload";

import { GermanPartnershipDocumentRequirements } from "./GermanPartnershipDocumentRequirements";
import { PartnershipDocumentRequirement } from "./PartnershipDocumentRequirement";

import { HavingTroubleDialog } from "src/components/routes/Setup/verification-status/common/HavingTroubleDialog";
import { CountryCodes } from "src/common/country";

export const PartnershipDocumentUpload: React.FC<RequiredActionCardProps> = ({
  creditorId,
  verificationId,
  entity,
}) => {
  const { creditorDetails } = useSetupData();
  const countryCode = entity.country_code as CountryCodes;

  const { validateFilesMinAndMaxWithSpecificCondition } =
    useUploadDocValidation({
      condition: countryCode === CountryCodes.DE,
      minFilesRequired: 2,
    });

  const creditorSubtype = creditorDetails?.detail
    ?.creditor_subtype as PartnershipType;

  const documentRequirements = getDocumentRequirements(
    countryCode,
    creditorSubtype
  );

  const { uploading, uploadDocuments } = useUploadDocuments(
    creditorId,
    verificationId
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UploadDocOnlyFormValues>({
    defaultValues: {
      upload_docs: [],
    },
  });

  const uploadSelectedDocuments = async (value: UploadDocOnlyFormValues) => {
    uploadDocuments(
      value.upload_docs,
      UploadedDocumentType.CertifiedCopyOfThePartnershipAgreement
    );
  };

  return (
    <Box>
      <Form onSubmit={handleSubmit(uploadSelectedDocuments)}>
        <AcceptedDocuments
          multiple
          documents={getAcceptedDocuments(countryCode, creditorSubtype)}
        />
        {documentRequirements && (
          <Box
            spaceAbove={1}
            gutterV={1.5}
            gutterH={1.5}
            borderColor={ColorPreset.BorderOnLight_04}
            borderRadius={1}
            borderWidth={1}
          >
            <H4 preset={TypePreset.Heading_01}>
              <Trans>The document provided must clearly show:</Trans>
            </H4>

            <Box spaceAbove={1}>
              <PartnershipDocumentRequirement
                requirements={documentRequirements}
              />
            </Box>
          </Box>
        )}

        {countryCode === CountryCodes.DE ? (
          <GermanPartnershipDocumentRequirements
            requirements={DE_PARTNERS_DOCUMENT_REQUIREMENTS_DEFAULT}
            creditorSubtype={creditorSubtype}
          />
        ) : null}
        <Box spaceAbove={2}>
          <ControlledFileUpload
            required
            name="upload_docs"
            control={control}
            uploading={uploading}
            validationRules={validateFilesMinAndMaxWithSpecificCondition}
          />
        </Box>
        {errors.upload_docs?.message ? (
          <FormError errorMessage={errors.upload_docs?.message} />
        ) : null}
        <Box spaceAbove={2}>
          <Button
            type="submit"
            variant={ButtonVariant.PrimaryOnLight}
            disabled={uploading}
          >
            <Trans>Upload</Trans>
          </Button>
        </Box>
      </Form>
      <HavingTroubleDialog sourceComponent={PartnershipDocumentUpload.name} />
    </Box>
  );
};

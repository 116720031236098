import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";

import { RequiredAction } from "../RequiredAction";
import { RequiredActionCardProps } from "../RequiredActionCard";

import { IdentityDocumentUpload } from "./IdentityDocumentUpload";

import { getPersonName } from "src/components/routes/Setup/verification-status/helpers/getPersonName";

export const PersonIdentityCard = (props: RequiredActionCardProps) => {
  const { entity, actionStatus, notificationEmail: nEmail } = props;
  const entityTitle = <Text className="fs-mask">{getPersonName(entity)}</Text>;
  const notificationEmail = <Text className="fs-mask">{nEmail}</Text>;

  switch (actionStatus) {
    case RequiredActionsStatus.Pending:
      return (
        <RequiredAction
          header={<Trans>Verify identity details for {entityTitle}</Trans>}
          description={
            <Trans>
              We need you to provide proof of identity for {entityTitle} to meet
              our regulatory requirements. You can upload a document or use your
              phone to submit proof.
            </Trans>
          }
          body={<IdentityDocumentUpload {...props} />}
        />
      );
    case RequiredActionsStatus.PendingResponse:
      return (
        <RequiredAction
          header={<Trans>Verify identity details for {entityTitle}</Trans>}
          description={
            <Trans>
              We&apos;ve received your document and our verification team has
              reached out to you at <b>{notificationEmail}</b> for further
              information.
            </Trans>
          }
        />
      );
    default:
      return null;
  }
};

import {
  Box,
  FontWeight,
  JustifyContent,
  MoneyText,
  MoneyTextVariant,
  Text,
  TypePreset,
  XYGrid,
} from "@gocardless/flux-react";
import {
  RequiredActionsDetails,
  BankAddress,
} from "@gocardless/api/dashboard/types";
import { ReactNode } from "react";
import { i18n } from "@lingui/core";

import {
  verificationDetails,
  verificationDetailsExperimental,
} from "./constants";

import { Currency } from "src/common/currencies";
import { useI18n } from "src/technical-integrations/i18n";
import { CopyButton } from "src/components/ui/copy-button/CopyButton";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { CountryCodes, countryCodeToName } from "src/common/country";

interface BankAccountDetailsProps {
  details: RequiredActionsDetails;
  copyableDetails?: boolean;
}

const BankAccountDetails: React.FC<BankAccountDetailsProps> = ({
  details,
  copyableDetails = false,
}) => {
  const [locale] = useI18n();

  const { isFlagEnabled: pennyTestImprovementsEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.PENNY_TEST_IMPROVEMENTS,
    });

  const verificationDetailsArray = pennyTestImprovementsEnabled
    ? verificationDetailsExperimental()
    : verificationDetails();

  return (
    <XYGrid templateColumns={["1fr", "1fr 1fr"]}>
      {verificationDetailsArray?.map(([title, key]) => {
        const currency = details.currency as Currency;
        const value = details[key as keyof RequiredActionsDetails];

        const copyable = (bankDetailValue: ReactNode) => {
          if (!bankDetailValue) return null;

          return (
            <Box layout="flex" justifyContent={JustifyContent.SpaceBetween}>
              {bankDetailValue}
              <CopyButton value={bankDetailValue.toString()} />
            </Box>
          );
        };

        const transform = () => {
          switch (key) {
            case "amount": {
              return (
                <MoneyText
                  amount={Number(details[key] || 0) / 100 ?? 0.01}
                  currency={currency}
                  locale={locale}
                  format="short"
                  variant={MoneyTextVariant.Flat}
                  data-testid="amount-field"
                />
              );
            }
            case "bank_address": {
              const { address_line1, city, country_code, postal_code } =
                value as BankAddress;
              const values = [
                address_line1,
                city,
                postal_code,
                country_code &&
                  countryCodeToName(i18n)[country_code as CountryCodes],
              ];
              return (
                <>{values.map((v) => (copyableDetails ? copyable(v) : v))}</>
              );
            }
            default: {
              const valueAsString = value as string;
              return copyableDetails ? copyable(valueAsString) : valueAsString;
            }
          }
        };

        return value
          ? [
              <Text
                key={`${key}-title`}
                preset={TypePreset.Body_02}
                spaceBelow={0.25}
              >
                {title}
                {":"}
              </Text>,
              <Text
                className="fs-mask"
                key={`${key}-value`}
                preset={TypePreset.Code_02}
                weight={FontWeight.Medium}
                spaceBelow={1}
              >
                {transform()}
              </Text>,
            ]
          : null;
      })}
    </XYGrid>
  );
};

export default BankAccountDetails;

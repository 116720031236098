import {
  AlignItems,
  Box,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  Icon,
  IconButton,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ReactNode, useState } from "react";

import { useSetupData } from "../../../common/hooks/useSetupData";
import { getBankAccountHolderDetails } from "../../helpers/getBankAccountHolderDetails";

import { BankAccountHolderDialog } from "./BankAccountHolderDialog";

export const BoldText = ({ children }: { children: ReactNode }) => (
  <Text
    weight={FontWeight.SemiBold}
    preset={TypePreset.Body_01}
    color={ColorPreset.TextOnLight_01}
  >
    {children}
  </Text>
);

export const InfoRowText = ({ children }: { children: React.ReactNode }) => (
  <Text
    weight={FontWeight.Normal}
    preset={TypePreset.Body_01}
    color={ColorPreset.TextOnLight_01}
  >
    {children}
  </Text>
);

export const InfoRowContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    layout="flex"
    flexDirection="row"
    alignItems={AlignItems.Center}
    css={{ gap: "8px" }}
  >
    {children}
  </Box>
);

export const BankVerificationDocumentsRequirements = ({
  accountNumberEnding: anEnding,
}: {
  accountNumberEnding: string;
}) => {
  const { creditorDetails } = useSetupData();

  const [renderDialog, setRenderDialog] = useState(false);

  if (
    !creditorDetails ||
    !creditorDetails.creditor_type ||
    !creditorDetails.detail
  ) {
    return null;
  }

  const bankAccountHolderDetails = getBankAccountHolderDetails(
    creditorDetails.creditor_type,
    creditorDetails.detail
  );
  const {
    isIndividual,
    creditorName: cName,
    personName: pName,
    tradingName: tName,
  } = bankAccountHolderDetails;

  const creditorName = <Text className="fs-mask">{cName}</Text>;
  const personName = <Text className="fs-mask">{pName}</Text>;
  const tradingName = <Text className="fs-mask">{tName}</Text>;
  const accountNumberEnding = <Text className="fs-mask">{anEnding}</Text>;
  const months = 3;

  const displayName = isIndividual ? (
    <>
      <BoldText>{personName}</BoldText>
      {tradingName && (
        <>
          <Text
            weight={FontWeight.Normal}
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_01}
          >
            {" "}
            <Trans>or</Trans>{" "}
          </Text>
          <BoldText>{tradingName}</BoldText>
        </>
      )}
    </>
  ) : (
    <BoldText>{creditorName}</BoldText>
  );

  return (
    <Box flexDirection="column" layout="flex" css={{ gap: "16px" }}>
      <InfoRowContainer>
        <Icon name={Glyph.TickCircle} color={Color.Success_300} />
        <InfoRowText>
          <Trans>Account name: {displayName} </Trans>
        </InfoRowText>
        <IconButton
          icon={Glyph.Tooltip}
          size={{
            gutters: ButtonGutter.Sm,
            base: ButtonSize.Sm,
          }}
          variant={ButtonVariant.TextOnLight}
          style={{
            verticalAlign: "middle",
            marginTop: -8,
            marginBottom: -8,
            marginLeft: "-6px",
          }}
          label="bank account holder hint"
          onClick={() => setRenderDialog(true)}
        />
      </InfoRowContainer>
      <InfoRowContainer>
        <Icon name={Glyph.TickCircle} color={Color.Success_300} />
        <InfoRowText>
          <Trans>
            Account number: <BoldText>{accountNumberEnding}</BoldText>
          </Trans>
        </InfoRowText>
      </InfoRowContainer>
      <InfoRowContainer>
        <Icon name={Glyph.TickCircle} color={Color.Success_300} />
        <InfoRowText>
          <Trans>
            Dated within the last <BoldText>{`${months} months`}</BoldText>
          </Trans>
        </InfoRowText>
      </InfoRowContainer>
      <InfoRowContainer>
        <Icon name={Glyph.CloseCircle} color={Color.Alert_500} />
        <InfoRowText>
          <Trans>Not blurry or cropped</Trans>
        </InfoRowText>
      </InfoRowContainer>
      {renderDialog && (
        <BankAccountHolderDialog
          bankAccountHolderDetails={bankAccountHolderDetails}
          onClose={() => setRenderDialog(false)}
        />
      )}
    </Box>
  );
};

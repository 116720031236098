import { Trans } from "@lingui/macro";
import { useEffect, useState } from "react";

import { verificationIsTakingLongerThanMaximumExpected } from "src/components/routes/Setup/verification-status/helpers/verificationIsTakingLongerThanMaximumExpected";

function usePersonalIdentityAdditionalDetail(lastStateChange: Date) {
  const [refresh, setRefresh] = useState(false);

  // Re-render the component in 1 minute
  useEffect(() => {
    const timer = setTimeout(() => {
      setRefresh((prev) => !prev);
    }, 60000);

    return () => clearTimeout(timer);
  }, [refresh]);

  const timeElapsedMs = new Date().getTime() - lastStateChange.getTime();

  if (isNaN(timeElapsedMs)) {
    return undefined;
  }

  if (timeElapsedMs < 60000) {
    return <Trans>This usually takes less than a minute to complete.</Trans>;
  } else if (!verificationIsTakingLongerThanMaximumExpected(lastStateChange)) {
    return (
      <Trans>
        This is taking longer than usual and could take up to 3 working days to
        complete.
      </Trans>
    );
  }

  return undefined;
}

export default usePersonalIdentityAdditionalDetail;

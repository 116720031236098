import { DetailResource } from "@gocardless/api/dashboard/types";

import { getPersonName } from "src/components/routes/Setup/verification-status/helpers/getPersonName";

export interface PartnerDetails {
  partners: string;
  number_of_partners: number;
}

export interface TrustDetails {
  name: string;
  trustees: string;
  ultimate_beneficial_owners: string;
}

export const formatPartners = (detail: DetailResource): string => {
  const partners = detail.partners;
  const names = partners?.map((partner) => getPersonName(partner));
  return names?.filter(Boolean).join(", ") || "";
};

export const formatTrustees = (detail: DetailResource): string => {
  const trustees = detail.trustees;
  const names = trustees?.map(
    (trustee) => trustee.name || getPersonName(trustee)
  );
  return names?.filter(Boolean).join(", ") || "";
};

export const formatUBOs = (detail: DetailResource): string => {
  const ubos = detail.ultimate_beneficial_owners;
  const names = ubos?.map((ubo) =>
    getPersonName({
      given_name: ubo.given_name || "",
      family_name: ubo.family_name || "",
    })
  );
  return names?.filter(Boolean).join(", ") || "";
};

export const getPartnersDetails = (detail: DetailResource): PartnerDetails => ({
  number_of_partners: detail.partners?.length || 0,
  partners: formatPartners(detail),
});

export const getTrustDetails = (detail: DetailResource): TrustDetails => ({
  name: detail.name || "",
  trustees: formatTrustees(detail),
  ultimate_beneficial_owners: formatUBOs(detail),
});

import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";

import { RequiredAction } from "../RequiredAction";
import { RequiredActionCardProps } from "../RequiredActionCard";

import { TrustConfirmationUpload } from "src/components/routes/Setup/verification-status/required-actions/trust-confirmation/TrustConfirmationUpload";

export const TrustConfirmationCard = (props: RequiredActionCardProps) => {
  const { actionStatus, entity, notificationEmail: nEmail } = props;
  const entityTitle = <Text className="fs-mask">{entity.name || ""}</Text>;
  const notificationEmail = <Text className="fs-mask">{nEmail}</Text>;

  switch (actionStatus) {
    case RequiredActionsStatus.Pending:
      return (
        <RequiredAction
          header={<Trans>Verify details for {entityTitle}</Trans>}
          description={
            <Trans>We need you to provide proof of your trust.</Trans>
          }
          body={<TrustConfirmationUpload {...props} />}
        />
      );
    case RequiredActionsStatus.PendingResponse:
      return (
        <RequiredAction
          header={<Trans>Verify details for {entityTitle}</Trans>}
          description={
            <Trans>
              We&apos;ve received your document and our verification team has
              reached out to you at <b>{notificationEmail}</b> for further
              information.
            </Trans>
          }
        />
      );
    default:
      return (
        <RequiredAction
          header={<Trans>We&apos;re verifying details for {entityTitle}</Trans>}
        />
      );
  }
};

import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";
import {
  AuddisLive,
  AvailableDebitSchemeEnum,
  BulkChangeRequestCreateRequestBody,
  BulkChangeType,
  ImportUploadTokenResource,
  PartnerIntegration,
  TransferType,
} from "@gocardless/api/dashboard/types";

import {
  BulkChangeRequestForm,
  DDCollectionMethod,
  MigrationType,
} from "./model";

import {
  castStringToDate,
  parseCalendarDateToString,
} from "src/common/date-helper";

export const FIRST_COLLECTION_WORKING_DAYS_LIMIT = 4;

export const addBusinessDays = (date: CalendarDate, daysToAdd: number) => {
  let resultDate = new CalendarDate(date.year, date.month, date.day);
  let count = 0;

  while (count < daysToAdd) {
    resultDate = resultDate.add({ days: 1 });
    const dayOfWeek = resultDate.toDate(getLocalTimeZone()).getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      count++;
    }
  }
  return resultDate;
};

export const getToday = () => today(getLocalTimeZone());
export const getNextMonth = () => getToday().add({ months: 1 });
export const isValidDate = (date: Partial<CalendarDate> | undefined) =>
  date &&
  date.year &&
  date.year.toString().length === 4 &&
  date.month &&
  date.day;

export const getBulkChangeRequestPayload = ({
  data,
}: {
  data: BulkChangeRequestForm & {
    encryptionKey: ImportUploadTokenResource["encryption_key"];
    objectKey: ImportUploadTokenResource["object_key"];
  };
}): BulkChangeRequestCreateRequestBody => ({
  encryption_key: data.encryptionKey,
  object_key: data.objectKey,
  old_sun_number: data.sun,
  scheme: AvailableDebitSchemeEnum.Bacs,
  ticket_params: {
    auddis_live:
      data.isSunAuddisLive === "true"
        ? AuddisLive.AuddisLive
        : AuddisLive.NonAuddisLive,
    bulk_change_date: castStringToDate(
      parseCalendarDateToString(data.migrationDate)
    ),
    bulk_change_type:
      data.migrationType === MigrationType.FULL
        ? BulkChangeType.Full
        : BulkChangeType.Partial,
    current_service_provider: data.sponsorBank,
    email_address: data.registeredEmail,
    ...(data.additionalEmails[0]?.value && {
      email_address_cc: data.additionalEmails[0].value,
    }),
    final_collection_date: castStringToDate(
      parseCalendarDateToString(data.finalCollectionDate)
    ),
    first_collection_date: castStringToDate(
      parseCalendarDateToString(data.firstCollectionDate)
    ),
    number_of_mandates: Number(data.numberOfCustomers),
    old_sun_name: data.sunFullName,
    partner_integration:
      data.hasPartnerIntegration === "true"
        ? PartnerIntegration.Yes
        : PartnerIntegration.No,
    short_sun_name: data.sunShortName,
    transfer_type:
      data.ddCollectionMethod === DDCollectionMethod.FACILITIES_MANAGEMENT
        ? TransferType.Fm
        : TransferType.Ma,
  },
});

import { AcceptedDocuments } from "../../common/constants/AcceptedDocuments";
import { AddressConfirmationCard } from "../required-actions/address-confirmation/AddressConfirmationCard";
import { AddressConfirmationStatus } from "../status-cards/status-item/AddressConfirmationStatus";

import { CountryCodes } from "src/common/country";

export const ADDRESS_ACCEPTED_DOCUMENTS_DEFAULT = [
  AcceptedDocuments.BankAccountStatement,
  AcceptedDocuments.UtilityBill,
];

export const ADDRESS_ACCEPTED_DOCUMENTS_COUNTRY_MAP: Record<
  string,
  AcceptedDocuments[]
> = {
  [CountryCodes.US]: [
    AcceptedDocuments.BankAccountStatement,
    AcceptedDocuments.UtilityBill,
    AcceptedDocuments.MajorCreditCardBillStatement,
  ],
};

export const AddressConfirmationConfig = {
  acceptedDocuments: (geo: CountryCodes) =>
    ADDRESS_ACCEPTED_DOCUMENTS_COUNTRY_MAP[geo] ||
    ADDRESS_ACCEPTED_DOCUMENTS_DEFAULT,

  statusComponent: AddressConfirmationStatus,

  actionComponent: AddressConfirmationCard,
};

import {
  AlignItems,
  Box,
  ButtonVariant,
  Dialog,
  Glyph,
  H3,
  Icon,
  Link,
  Space,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { ZendeskLink } from "src/components/ui/ZendeskLink/ZendeskLink";

interface HelpModalProps {
  helpModalOpen: boolean;
  toggleHelpModalOpen: () => void;
}
export const HelpModal: React.FC<HelpModalProps> = ({
  helpModalOpen,
  toggleHelpModalOpen,
}) => (
  <Dialog
    open={helpModalOpen}
    closeAction={{ onClose: toggleHelpModalOpen, label: "Close" }}
    aria-labelledby="dialogheader"
    data-testid="helpmodal"
    header={{
      content: (
        <>
          <H3 size={4} id="dialogheader">
            <Trans id="need-help.support-title">
              Our support team are available to help
            </Trans>
          </H3>
        </>
      ),
      gutterH: 3,
    }}
  >
    <Content />
  </Dialog>
);

const Content: React.FC = () => (
  <>
    <Space v={0.5} />
    <Box layout="flex" alignItems={AlignItems.Center}>
      <Icon name={Glyph.Guide} size="18px" />
      <Box gutterH={1}>
        <Link
          variant={ButtonVariant.Inline}
          href="https://hub.gocardless.com/"
          target="_blank"
        >
          <Trans id="need-help.view-support-guides">
            View our support guides
          </Trans>
        </Link>
      </Box>
    </Box>

    <Space v={1} />

    <Box layout="flex" alignItems={AlignItems.Center}>
      <Icon name={Glyph.MailPlane} size="18px" />
      <Box gutterH={1}>
        <Link variant={ButtonVariant.Inline} href="mailto:help@gocardless.com">
          <Trans id="need-help.support-email">Email help@gocardless.com</Trans>
        </Link>
      </Box>
    </Box>

    <Space v={1} />

    <Box layout="flex" alignItems={AlignItems.Center}>
      <Icon name={Glyph.Support} size="18px" />
      <Box gutterH={1}>
        <ZendeskLink
          data-tracking-label="Help modal"
          variant={ButtonVariant.Inline}
          formId={134125}
        >
          <Trans id="need-help.request-support-customer-hub">
            Request support via our Customer Hub
          </Trans>
        </ZendeskLink>
      </Box>
    </Box>
    <Space v={1.5} />
  </>
);

import { FontWeight, PlainLink, Text } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";

export const KbisDownloadGuidance = () => {
  const { isVariationOn: isKbisUploadsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.VERIFICATIONS_IMPROVE_KBIS_UPLOADS,
  });
  const { sendEvent } = useSegment();

  return isKbisUploadsEnabled ? (
    <Text>
      <Trans>
        (You can access your digital KBIS extract online{" "}
        <PlainLink
          href="https://monidenum.fr/"
          target="_blank"
          weight={FontWeight.SemiBold}
          decoration="underline"
          onClick={() =>
            sendEvent(TrackingEvent.VERIFICATION_KBIS_GUIDANCE_CLICKED)
          }
        >
          <Trans>here</Trans>
        </PlainLink>
      </Trans>
    </Text>
  ) : null;
};

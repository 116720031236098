import {
  CreditorType,
  RequiredActionsName,
} from "@gocardless/api/dashboard/types";
import { FC } from "react";

import { DocumentRequirement } from "../constants/DocumentRequirement";
import { AcceptedDocuments } from "../../common/constants/AcceptedDocuments";
import { RequiredActionCardProps } from "../required-actions/RequiredActionCard";
import { RequiredDocument } from "../required-documents/useRequiredDocuments";

import { BankAccountIdentityConfig } from "./BankAccountIdentityConfig";
import { CompanyIdentityConfig } from "./CompanyIdentityConfig";
import { PersonIdentityConfig } from "./PersonIdentityConfig";
import { VerificationDepositConfig } from "./VerificationDepositConfig";
import { CompanyUBOsConfig } from "./CompanyUBOsConfig";
import { AddressConfirmationConfig } from "./AddressConfirmationConfig";
import { PartnersConfirmationConfig } from "./PartnersConfirmationConfig";
import { PartnershipIdentityConfig } from "./PartnershipIdentityConfig";

import { TrustConfig } from "src/components/routes/Setup/verification-status/config/TrustConfig";
import { CharityConfig } from "src/components/routes/Setup/verification-status/config/CharityConfig";
import { HighRiskConfig } from "src/components/routes/Setup/verification-status/config/HighRiskConfig";
import { CountryCodes } from "src/common/country";

export interface UploadConfig {
  acceptedDocuments?: (geo: CountryCodes) => AcceptedDocuments[];
  documentRequirements?: (
    geo: CountryCodes,
    isKbisUploadsEnabled?: boolean
  ) => DocumentRequirement[];
  statusComponent: FC<{
    requiredDocument: RequiredDocument;
    creditorType: CreditorType;
  }>;
  actionComponent?: FC<RequiredActionCardProps>;
}

export const requiredActionToConfigMap: Record<
  RequiredActionsName,
  UploadConfig | null
> = {
  [RequiredActionsName.SendVerificationDeposit]: VerificationDepositConfig,
  [RequiredActionsName.ProvideBankStatement]: BankAccountIdentityConfig,
  [RequiredActionsName.ProvideCharityConfirmation]: CharityConfig,
  [RequiredActionsName.ProvideIdentityConfirmation]: PersonIdentityConfig,
  [RequiredActionsName.ProvideAnyIdentityConfirmation]: PersonIdentityConfig,
  [RequiredActionsName.ProvidePrimaryUserConfirmation]: PersonIdentityConfig,
  [RequiredActionsName.ProvideCompanyConfirmation]: CompanyIdentityConfig,
  [RequiredActionsName.ProvideGcSasDocuments]: CompanyIdentityConfig,
  [RequiredActionsName.ProvideCompanyUbosConfirmation]: CompanyUBOsConfig,
  [RequiredActionsName.ProvideAddressConfirmation]: AddressConfirmationConfig,
  [RequiredActionsName.ProvideAnyAddressConfirmation]:
    AddressConfirmationConfig,
  [RequiredActionsName.ProvideHighRiskGcSasDocuments]: HighRiskConfig,
  [RequiredActionsName.ProvideTrustConfirmation]: TrustConfig,
  [RequiredActionsName.ProvidePartnersConfirmation]: PartnersConfirmationConfig,
  [RequiredActionsName.ProvidePartnershipConfirmation]:
    PartnershipIdentityConfig,

  // deprecated
  [RequiredActionsName.ProvidePrimaryDirectorConfirmation]: null,
  [RequiredActionsName.ProvideSupportQuestions]: null,
  [RequiredActionsName.ProvideUbosConfirmation]: null,

  // not shown on status page
  [RequiredActionsName.ProvideSecondaryUserConfirmation]: null,
  [RequiredActionsName.BorderlessCddRemediation]: null,
  [RequiredActionsName.FillAchDetails]: null,
};

export const getUploadConfig = (
  requiredActionName: RequiredActionsName,
  creditorGeo: CountryCodes,
  isKbisUploadsEnabled?: boolean
) => {
  const {
    acceptedDocuments,
    documentRequirements,
    statusComponent,
    actionComponent,
  } = requiredActionToConfigMap[requiredActionName] || {};

  return {
    acceptedDocuments: acceptedDocuments ? acceptedDocuments(creditorGeo) : [],
    documentRequirements:
      documentRequirements &&
      documentRequirements(creditorGeo, isKbisUploadsEnabled),
    statusComponent,
    actionComponent,
  };
};

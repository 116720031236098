import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

import { KbisDownloadGuidance } from "../../verification-status/common/KbisDownloadGuidance";

export enum AcceptedDocuments {
  /* Person Identity */
  Passport = "passport",
  DrivingLicence = "driving_licence",
  NationalIdentityCard = "national_identity_card",

  /* Bank account */
  BankAccountStatement = "bank_account_statement",
  BankConfirmationLetter = "bank_confirmation_letter",
  BankBankingScreenshot = "bank_banking_screenshot",
  RIBBankDocument = "rib_banking_document",
  OnlineBankingScreenshot = "online_banking_screenshot",
  AccountOpeningStatement = "account_opening_statement",

  /* Charity confirmation */
  CharityRegisterExtract3Months = "charity_register_extract_3_months",
  CharityRegisterExtract12Months = "charity_register_extract_12_months",
  AnnualGeneralMeetingMinutes = "annual_general_meeting_minutes",

  /* Company identity */
  CertificateOfIncorporation = "certificate_of_incorporation",
  CompanyAnnualReturn = "company_annual_return",
  CompanyEntryNzbn = "company_entry_nzbn",
  CompanyExtractAsic = "company_extract_asic",
  CompanyZefixCantonRegistryEntry = "company_zefix_canton_registry_entry",
  IncorporationDocuments = "incorporation_documents",
  RecentAnnualReport = "recent_annual_report",
  RecentConfirmationStatement = "recent_confirmation_statement",
  RecentFilingFromStateRegistry = "recent_filing_from_state_registry",
  CertificateOfConfirmationFromCipcRegister = "certificate_of_confirmation_from_cipc_register",
  CertificateOfGoodStanding = "certificate_of_good_standing",
  GovernmentIssuedBusinessLicense = "government_issued_business_license",

  /* Company UBOs */
  CompanyMemorandumArticlesOfAssociation = "company_memorandum_articles_of_association",
  LetterFromLegalFirm = "letter_from_legal_firm",

  /* GC SAS Company identity */
  CertifiedListOfDirectors = "certified_list_of_directors",
  CompanyAnnualReturnCro = "company_annual_return_cro",
  CompanyArsredovisning = "company_arsredovisning",
  CompanyConstitutionCbe = "company_constitution_cbe",
  CompanyDeedOfIncorporationMercantilRegister = "company_deed_of_incorporation_mercantil_register",
  CompanyEntryAjpesRegister = "company_entry_ajpes_register",
  CompanyEntryAtRegistry = "company_entry_at_registry",
  CompanyEntryCentralBusinessRegisterDk = "company_entry_central_business_register_dk",
  CompanyEntryVerejnyRejstrik = "company_entry_verejny_rejstrik",
  CompanyKbisExtract = "company_kbis_extract",
  CompanyMfsaEntry = "company_mfsa_entry",
  CompanyNtroRegistration = "company_ntro_registration",
  CompanyObchodnyRegisterEntry = "company_obchodny_register_entry",
  CompanyRegisterOfNpleEntry = "company_register_of_nple_entry",
  CompanyRegistrationBronnoysundregistrene = "company_registration_bronnoysundregistrene",
  CompanyRegistrationEuropeanJusticePortal = "company_registration_european_justice_portal",
  CompanyRegistrationKvk = "company_registration_kvk",
  CompanyRegistrationTradeRegister = "company_registration_trade_register",
  CompanyRegistrationUnternehmensregister = "company_registration_unternehmensregister",
  CompanyReportRegistroDelleImprese = "company_report_registro_delle_imprese",
  CompanySuskiRegistarEntry = "company_suski_registar_entry",
  KrsRegistrationEntry = "krs_registration_entry",
  RegistarOfCompaniesOfficialReceiverEntry = "registar_of_companies_official_receiver_entry",

  /* High risk GC SAS documents */
  SignedStatuses = "signed_statuses",
  ShareOwnershipStructure = "share_ownership_structure",
  AnnualReturn = "annual_return",
  MemorandumOfAssociation = "memorandum_of_association",
  ArticlesOfAssociation = "articles_of_association",
  FiledAccounts = "filed_accounts",
  TaxReturn = "tax_return",
  FinancialBalance = "financial_balance",
  MostRecentAnnualAccounts = "most_recent_annual_accounts",
  AnnualReturnOrReport = "annual_return_or_report",
  MostRecentAnnualReportOrAccounts = "most_recent_annual_report_or_accounts",

  /* Proof of address */
  UtilityBill = "utility_bill",
  MajorCreditCardBillStatement = "major_credit_card_bill_statement",

  /* Trust document */
  TrustDeed = "trust_deed",

  /* partners documents */
  RecentAnnualReportOrReturn = "recent_annual_report_or_return",
  CertifiedOwnershipStructure = "certified_ownership_structure",
  RecentAsicFilingWithOwnershipStructure = "recent_asic_filing_with_ownership_structure",
  SignedTrustDeed = "signed_trust_deed",

  /* partnership documents */
  FullSignedPartnershipAgreement = "full_signed_partnership_agreement",
  RecentAsicFiling = "recent_asic_filing",
  SignedPartnershipDeed = "signed_partnership_deed",
  PartnershipEntryOnAProfessionalAssociationMembershipDirectory = "partnership_entry_on_a_professional_association_membership_directory",
  CertifiedCopyOfThePartnershipAgreement = "certified_copy_of_the_partnership_agreement",
  CurrentPrintoutFromTheHandelsregister = "current_printout_from_the_handelsregister",
  CompanyExtractFromOfficialCompanyRegister = "company_extract_from_official_company_register",
  CopyOfGewerbeanmeldung = "copy_of_gewerbeanmeldung",
}

export const PERSON_IDENTITY_DOCUMENTS = [
  AcceptedDocuments.Passport,
  AcceptedDocuments.DrivingLicence,
  AcceptedDocuments.NationalIdentityCard,
];

export const ACCEPTED_DOCUMENTS_GUIDANCE_COMPONENT_MAP: Partial<
  Record<AcceptedDocuments, React.ComponentType | null>
> = {
  [AcceptedDocuments.CompanyKbisExtract]: KbisDownloadGuidance,
};

export const AcceptedDocumentsNameMap = (
  i18n: I18n
): Record<AcceptedDocuments, string> => ({
  /* Person Identity */
  [AcceptedDocuments.Passport]: i18n._(t`Passport`),
  [AcceptedDocuments.DrivingLicence]: i18n._(t`Driving licence`),
  [AcceptedDocuments.NationalIdentityCard]: i18n._(t`National identity card`),

  /* Bank account */
  [AcceptedDocuments.BankAccountStatement]: i18n._(t`Bank account statement`),
  [AcceptedDocuments.BankConfirmationLetter]: i18n._(
    t`Letter from your bank confirming your details`
  ),
  [AcceptedDocuments.BankBankingScreenshot]: i18n._(
    t`Screenshot of your online banking`
  ),
  [AcceptedDocuments.RIBBankDocument]: i18n._(t`RIB banking document`),
  [AcceptedDocuments.OnlineBankingScreenshot]: i18n._(
    t`Screenshot of your online banking`
  ),
  [AcceptedDocuments.AccountOpeningStatement]: i18n._(
    t`Account opening statement`
  ),

  /* Charity confirmation */
  [AcceptedDocuments.CharityRegisterExtract3Months]: i18n._(
    t`Extract from charity register (dated within last 3 months)`
  ),
  [AcceptedDocuments.CharityRegisterExtract12Months]: i18n._(
    t`Extract from charity register (dated within last 12 months)`
  ),
  [AcceptedDocuments.AnnualGeneralMeetingMinutes]: i18n._(
    t`Copy of last annual general meeting minutes`
  ),

  /* Company identity */
  [AcceptedDocuments.CertificateOfIncorporation]: i18n._(
    t`Certificate of incorporation`
  ),
  [AcceptedDocuments.CompanyAnnualReturn]: i18n._(t`Annual return`),
  [AcceptedDocuments.CompanyEntryNzbn]: i18n._(
    t`Company registration entry on the New Zealand Companies register`
  ),
  [AcceptedDocuments.CompanyExtractAsic]: i18n._(
    t`ASIC current company extract`
  ),
  [AcceptedDocuments.CompanyZefixCantonRegistryEntry]: i18n._(
    t`Zefix registry entry or canton registry entry`
  ),
  [AcceptedDocuments.IncorporationDocuments]: i18n._(
    t`Incorporation documents`
  ),
  [AcceptedDocuments.RecentAnnualReport]: i18n._(t`A recent annual report`),
  [AcceptedDocuments.RecentConfirmationStatement]: i18n._(
    t`Recent confirmation statement`
  ),
  [AcceptedDocuments.RecentFilingFromStateRegistry]: i18n._(
    t`A recent filing from your state registry`
  ),
  [AcceptedDocuments.CertificateOfConfirmationFromCipcRegister]: i18n._(
    t`Certificate of Confirmation from the CIPC register`
  ),
  [AcceptedDocuments.CertificateOfGoodStanding]: i18n._(
    t`Certificate of good standing`
  ),
  [AcceptedDocuments.GovernmentIssuedBusinessLicense]: i18n._(
    t`An unexpired government-issued business license`
  ),

  /* Company UBOs */
  [AcceptedDocuments.CompanyMemorandumArticlesOfAssociation]: i18n._(
    t`Memorandum and Articles of Association`
  ),
  [AcceptedDocuments.LetterFromLegalFirm]: i18n._(
    t`Letter on headed paper from a legal or accountancy firm`
  ),

  /* GC SAS Company identity */
  [AcceptedDocuments.CertifiedListOfDirectors]: i18n._(
    t`Current extract from your official company register (e.g. Certidão permanente)`
  ),
  [AcceptedDocuments.CompanyAnnualReturnCro]: i18n._(
    t`Current extract from your official company register (e.g.Company printout)`
  ),
  [AcceptedDocuments.CompanyArsredovisning]: i18n._(
    t`Current extract from your official company register (e.g. Registreringsbevis)`
  ),
  [AcceptedDocuments.CompanyConstitutionCbe]: i18n._(
    t`Current extract from your official company register (e.g Registered entity data extract)`
  ),
  [AcceptedDocuments.CompanyDeedOfIncorporationMercantilRegister]: i18n._(
    t`Current extract from your official company register`
  ),
  [AcceptedDocuments.CompanyEntryAjpesRegister]: i18n._(
    t`Current extract from your official company register`
  ),
  [AcceptedDocuments.CompanyEntryAtRegistry]: i18n._(
    t`Current extract from your official company register`
  ),
  [AcceptedDocuments.CompanyEntryCentralBusinessRegisterDk]: i18n._(
    t`Current extract from your official company register`
  ),
  [AcceptedDocuments.CompanyEntryVerejnyRejstrik]: i18n._(
    t`Current extract from your official company register (e.g. Výpis)`
  ),
  [AcceptedDocuments.CompanyKbisExtract]: i18n._(t`KBIS extract`),
  [AcceptedDocuments.CompanyMfsaEntry]: i18n._(
    t`Current extract from your official company register`
  ),
  [AcceptedDocuments.CompanyNtroRegistration]: i18n._(
    t`Current extract from your official company register (e.g. Furnizare informatii)`
  ),
  [AcceptedDocuments.CompanyObchodnyRegisterEntry]: i18n._(
    t`Current extract from the Obchodný register (e.g. Výpis)`
  ),
  [AcceptedDocuments.CompanyRegisterOfNpleEntry]: i18n._(
    t`Current extract from your official company register`
  ),
  [AcceptedDocuments.CompanyRegistrationBronnoysundregistrene]: i18n._(
    t`Current extract from your official company register (e.g Registerutskrift)`
  ),
  [AcceptedDocuments.CompanyRegistrationEuropeanJusticePortal]: i18n._(
    t`Current extract from your official company register (e.g. company profile extract)`
  ),
  [AcceptedDocuments.CompanyRegistrationKvk]: i18n._(
    t`Current extract from your official company register (e.g certified KVK extract)`
  ),
  [AcceptedDocuments.CompanyRegistrationTradeRegister]: i18n._(
    t`Current extract from your official company register (e.g. Rekisteriotteen tiedot)`
  ),
  [AcceptedDocuments.CompanyRegistrationUnternehmensregister]: i18n._(
    t`Current extract from the Handelsregister`
  ),
  [AcceptedDocuments.CompanyReportRegistroDelleImprese]: i18n._(
    t`Current extract from your official company register`
  ),
  [AcceptedDocuments.CompanySuskiRegistarEntry]: i18n._(
    t`Current extract from your official company register (e.g. Izvadak)`
  ),
  [AcceptedDocuments.KrsRegistrationEntry]: i18n._(
    t`Current extract from your official company register`
  ),
  [AcceptedDocuments.RegistarOfCompaniesOfficialReceiverEntry]: i18n._(
    t`Current extract from your official company register (e.g. Electronic filing extract)`
  ),

  [AcceptedDocuments.SignedStatuses]: i18n._(t`Up to date signed statutes`),
  [AcceptedDocuments.ShareOwnershipStructure]: i18n._(
    t`Share ownership structure certified by an external body`
  ),
  [AcceptedDocuments.AnnualReturn]: i18n._(t`Last filed annual return`),
  [AcceptedDocuments.MemorandumOfAssociation]: i18n._(
    t`Memorandum of association`
  ),
  [AcceptedDocuments.ArticlesOfAssociation]: i18n._(t`Articles of association`),
  [AcceptedDocuments.FiledAccounts]: i18n._(t`Recent annual accounts`),
  [AcceptedDocuments.TaxReturn]: i18n._(t`Filed tax statement`),
  [AcceptedDocuments.FinancialBalance]: i18n._(
    t`Balance sheet (or balance sheet forecast if your business is newly incorporated)`
  ),
  [AcceptedDocuments.MostRecentAnnualAccounts]: i18n._(
    t`Most recent annual accounts`
  ),
  [AcceptedDocuments.AnnualReturnOrReport]: i18n._(
    t`Last filed annual return or report`
  ),
  [AcceptedDocuments.MostRecentAnnualReportOrAccounts]: i18n._(
    t`Most recent annual report or accounts`
  ),

  /* Proof of address */
  [AcceptedDocuments.UtilityBill]: i18n._(t`Utility bill`),
  [AcceptedDocuments.MajorCreditCardBillStatement]: i18n._(
    t`Major credit card bill statement`
  ),

  /* Trust documents */
  [AcceptedDocuments.TrustDeed]: i18n._(t`Trust deed`),

  /* partners documents */
  [AcceptedDocuments.RecentAnnualReportOrReturn]: i18n._(
    t`Most recent annual return or report`
  ),
  [AcceptedDocuments.CertifiedOwnershipStructure]: i18n._(
    t`Ownership structure certified by an external legal or accountancy firm`
  ),
  [AcceptedDocuments.RecentAsicFilingWithOwnershipStructure]: i18n._(
    t`Recent company ASIC filing which details the company ownership structure`
  ),
  [AcceptedDocuments.SignedTrustDeed]: i18n._(
    t`Signed trust deed which details the trustees, settlors and beneficiaries`
  ),

  /* partnership documents */
  [AcceptedDocuments.FullSignedPartnershipAgreement]: i18n._(
    t`Full signed partnership agreement`
  ),
  [AcceptedDocuments.RecentAsicFiling]: i18n._(t`Recent ASIC filing`),
  [AcceptedDocuments.SignedPartnershipDeed]: i18n._(t`Signed partnership deed`),
  [AcceptedDocuments.PartnershipEntryOnAProfessionalAssociationMembershipDirectory]:
    i18n._(t`Partnership's entry on the provincial or territorial register`),
  [AcceptedDocuments.CertifiedCopyOfThePartnershipAgreement]: i18n._(
    t`Cerified copy of the partenership agreement`
  ),
  [AcceptedDocuments.CurrentPrintoutFromTheHandelsregister]: i18n._(
    t`Current printout from the handelsregister dated within the last three months`
  ),
  [AcceptedDocuments.CompanyExtractFromOfficialCompanyRegister]: i18n._(
    t`Company extract from your official company register dated within the last three months`
  ),
  [AcceptedDocuments.CopyOfGewerbeanmeldung]: i18n._(
    t`A stamped copy of the Gewerbeanmeldung (GeWa 1) for each partner`
  ),
});

export const AcceptedDocumentsWithMultipleSides = [
  AcceptedDocuments.NationalIdentityCard,
  AcceptedDocuments.DrivingLicence,
];

import {
  Box,
  Button,
  ButtonVariant,
  ColorPreset,
  Field,
  FontWeight,
  Form,
  H4,
  Separator,
  TypePreset,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { UploadedDocumentType } from "@gocardless/api/dashboard/types";
import { useForm } from "react-hook-form";
import { useLingui } from "@lingui/react";

import { AcceptedDocuments } from "../../common/AcceptedDocuments";
import { useUploadDocuments } from "../../required-documents/useUploadDocuments";
import { HavingTroubleDialog } from "../../common/HavingTroubleDialog";
import { RequiredActionCardProps } from "../RequiredActionCard";
import FormError from "../../common/FormError";
import useHasSubmitted from "../../hooks/useHasSubmitted";
import { BankAccountFormValues } from "../../constants/DocumentRequirement";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { ControlledFileUpload } from "../../common/ControlledFileUpload";
import { ControlledCheckBox } from "../../common/ControlledCheckBox";

import { BankVerificationDocumentsRequirements } from "./BankVerificationDocumentsRequirements";

import Sentry from "src/technical-integrations/sentry/sentry";

export const BankDocumentUpload: React.FC<RequiredActionCardProps> = ({
  creditorId,
  verificationId,
  acceptedDocuments,
  bankAccountNumberEnding,
}) => {
  const { uploading, uploadDocuments } = useUploadDocuments(
    creditorId,
    verificationId
  );

  const {
    uploadDocValidationMessages: { confirmInfo },
    validateFilesMaxOnly,
  } = useUploadDocValidation();

  const { hasSubmitted, handleOnError, resetHasSubmitted } = useHasSubmitted();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BankAccountFormValues>({
    defaultValues: {
      confirm_bank_account: false,
      upload_docs: [],
    },
  });
  const { i18n } = useLingui();
  if (!bankAccountNumberEnding) {
    Sentry.captureMessage(
      `No account number available for bank account verification ${verificationId}`
    );
    return null;
  }

  const uploadSelectedDocuments = async (value: BankAccountFormValues) => {
    await uploadDocuments(
      value.upload_docs,
      UploadedDocumentType.BankStatement
    );
  };

  return (
    <Box>
      <AcceptedDocuments
        documents={acceptedDocuments}
        caller="bank_document_upload"
      />
      <Form onSubmit={handleSubmit(uploadSelectedDocuments, handleOnError)}>
        <Box spaceBelow={1} spaceAbove={1}>
          <Field>
            <Box
              spaceAbove={0.25}
              gutterV={1.5}
              gutterH={1.5}
              borderColor={ColorPreset.BorderOnLight_04}
              borderRadius={1}
              borderWidth={1}
            >
              <H4 preset={TypePreset.Heading_01} weight={FontWeight.Normal}>
                <Trans>
                  Documents in the form of PDFs are preferred, however we can
                  also accept photos and screenshots provided that they include
                  the following requirements:
                </Trans>
              </H4>

              <Box spaceAbove={1.5} spaceBelow={1.5}>
                <BankVerificationDocumentsRequirements
                  accountNumberEnding={bankAccountNumberEnding}
                />
              </Box>

              <Separator />

              <Box spaceAbove={1.5}>
                <ControlledCheckBox
                  name="confirm_bank_account"
                  control={control}
                  labelText={i18n._(
                    t`I confirm that the document I provide shows the above information.`
                  )}
                  description={i18n._(
                    t`Invalid proof will prevent us from processing any payments.`
                  )}
                  required={confirmInfo}
                  resetHasSubmitted={resetHasSubmitted}
                />
              </Box>
            </Box>
          </Field>
        </Box>
        {errors.confirm_bank_account?.message && hasSubmitted ? (
          <FormError errorMessage={errors.confirm_bank_account.message} />
        ) : null}
        <Box spaceAbove={2}>
          <ControlledFileUpload
            required
            name="upload_docs"
            control={control}
            uploading={uploading}
            validationRules={validateFilesMaxOnly}
          />
        </Box>
        {errors.upload_docs?.message ? (
          <FormError errorMessage={errors.upload_docs.message} />
        ) : null}
        <Box spaceAbove={2}>
          <Button
            type="submit"
            variant={ButtonVariant.PrimaryOnLight}
            disabled={uploading}
          >
            <Trans>Upload</Trans>
          </Button>
        </Box>
      </Form>
      <HavingTroubleDialog
        sourceComponent={BankDocumentUpload.name}
        {...{ bankAccountNumberEnding }}
      />
    </Box>
  );
};

import { CountryCodes } from "src/common/country";
import { AcceptedDocuments } from "src/components/routes/Setup/common/constants/AcceptedDocuments";
import { DocumentRequirement } from "src/components/routes/Setup/verification-status/constants/DocumentRequirement";
import { RequiredDocumentStatus } from "src/components/routes/Setup/verification-status/status-cards/status-item/RequiredDocumentStatus";
import { TrustConfirmationCard } from "src/components/routes/Setup/verification-status/required-actions/trust-confirmation/TrustConfirmationCard";

const ACCEPTED_DOCUMENTS_DEFAULT = [AcceptedDocuments.TrustDeed];

const ACCEPTED_DOCUMENTS_COUNTRY_MAP: Record<string, AcceptedDocuments[]> = {
  [CountryCodes.AU]: [AcceptedDocuments.TrustDeed],
};

const DOCUMENT_REQUIREMENTS_DEFAULT = [
  DocumentRequirement.TrustName,
  DocumentRequirement.NamesOfTrustees,
  DocumentRequirement.NamesOfUltimateBeneficialOwners,
];

const DOCUMENT_REQUIREMENTS_COUNTRY_MAP: Record<string, DocumentRequirement[]> =
  {
    [CountryCodes.AU]: [
      DocumentRequirement.TrustName,
      DocumentRequirement.NamesOfTrustees,
      DocumentRequirement.NamesOfUltimateBeneficialOwners,
    ],
  };

export const TrustConfig = {
  acceptedDocuments: (geo: CountryCodes) =>
    ACCEPTED_DOCUMENTS_COUNTRY_MAP[geo] || ACCEPTED_DOCUMENTS_DEFAULT,

  documentRequirements: (geo: CountryCodes) =>
    DOCUMENT_REQUIREMENTS_COUNTRY_MAP[geo] || DOCUMENT_REQUIREMENTS_DEFAULT,

  statusComponent: RequiredDocumentStatus,

  actionComponent: TrustConfirmationCard,
};

import { HTTPError } from "@gocardless/api/utils/api";
import {
  CreditorResource,
  CreditorDetailResource,
  CreditorDetailUpdateResponseBody,
} from "@gocardless/api/dashboard/types";
import {
  CreditorDetailUpdateRequestBody,
  useCreditorDetailSelf,
  useCreditorDetailUpdate,
} from "@gocardless/api/dashboard/creditor-detail";

import { usePrimaryCreditor } from "src/libraries/creditor/hooks";

interface UseSetupData {
  loaded: boolean;
  creditor: CreditorResource | null | undefined;
  creditorDetails: CreditorDetailResource | undefined;
  updateCreditorDetail: (
    b?: CreditorDetailUpdateRequestBody | undefined
  ) => Promise<CreditorDetailUpdateResponseBody | undefined>;
}

export const useSetupData = (
  onSuccess = () => {},
  onError: (error: HTTPError) => void = () => {}
): UseSetupData => {
  const creditor = usePrimaryCreditor();
  const { data: creditorDetails, revalidate } = useCreditorDetailSelf(
    creditor?.id || null
  );

  const [updateCreditorDetail] = useCreditorDetailUpdate(creditor?.id || "", {
    onSuccess: () => {
      revalidate().then(() => onSuccess());
    },
    onError,
  });

  return {
    loaded: !!creditor && !!creditorDetails,
    creditor,
    creditorDetails: creditorDetails?.creditor_details,
    updateCreditorDetail,
  };
};

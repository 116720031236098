import {
  Button,
  ButtonVariant,
  ButtonLayout,
  Glyph,
  Icon,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { OnfidoApi } from "onfido-sdk-ui";

import { getOnfidoToken } from "./useOnfido";
import { VerifyWithOnfidoPopup } from "./VerifyWithOnfidoPopup";

interface TriggerOnfidoFlowProps {
  creditorId: string;
  verificationId: string;
  showOnfidoModal: boolean;
  updateRequiredDocuments: () => Promise<void>;
  setShowOnfidoModal: Dispatch<SetStateAction<boolean>>;
  setShouldRenderOnfidoUpload: Dispatch<SetStateAction<boolean>>;
  setMutationFetchingPaused:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
}

export const TriggerOnfidoFlow = ({
  creditorId,
  verificationId,
  showOnfidoModal,
  setMutationFetchingPaused,

  updateRequiredDocuments,
  setShowOnfidoModal,
  setShouldRenderOnfidoUpload,
}: TriggerOnfidoFlowProps) => {
  const [onfido, setOnfido] = useState<OnfidoApi>();
  const [onfidoToken, setOnfidoToken] = useState<string | undefined>();

  // We must import it this way because our current version of NodeJS doesn't support the ES6 'export' syntax:
  useEffect(() => {
    import("onfido-sdk-ui").then((importedOnfido) => {
      setOnfido(importedOnfido.Onfido);
    });
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getOnfidoToken(verificationId);

      if (token) {
        setOnfidoToken(token);
      } else {
        setShouldRenderOnfidoUpload(false);
      }
    };

    fetchToken();
  }, [setShouldRenderOnfidoUpload, verificationId]);

  return onfidoToken && onfido ? (
    <>
      <Button
        type="submit"
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, ButtonLayout.Inline]}
      >
        <Trans>Start</Trans>
      </Button>

      {showOnfidoModal && (
        <VerifyWithOnfidoPopup
          onfidoToken={onfidoToken}
          creditorId={creditorId}
          verificationId={verificationId}
          onfido={onfido}
          updateRequiredDocuments={updateRequiredDocuments}
          onClose={() => {
            setShowOnfidoModal(false);
            if (setMutationFetchingPaused) {
              setMutationFetchingPaused(false);
            }
          }}
          onOnfidoError={() => setShouldRenderOnfidoUpload(false)}
        />
      )}
    </>
  ) : (
    <Button
      data-testid="loading"
      variant={ButtonVariant.PrimaryOnLight}
      layout={[ButtonLayout.Full, ButtonLayout.Inline]}
      disabled
    >
      <Icon name={Glyph.Spinner} size="20px" />
    </Button>
  );
};

import { useSegment } from "src/technical-integrations/segment/useSegment";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";

export interface Segment {
  sendEvent: (name: string, params?: {}) => void;
  sendEventPromise: (name: string, params?: {}) => Promise<void>;
}

export const useSegmentForSetup = (): Segment => {
  const {
    sendEvent: defaultSendEvent,
    sendEventPromise: defaultSendEventPromise,
  } = useSegment();
  const creditor = usePrimaryCreditor();

  const sendEvent = (name: string, params?: {}) => {
    defaultSendEvent(name, {
      geo: creditor?.geo,
      creditor_type: creditor?.creditor_type,
      sub_segment: creditor?.sub_segment,
      ...params,
    });
  };

  const sendEventPromise = (name: string, params?: {}) =>
    defaultSendEventPromise(name, {
      geo: creditor?.geo,
      creditor_type: creditor?.creditor_type,
      sub_segment: creditor?.sub_segment,
      ...params,
    });

  return {
    sendEvent,
    sendEventPromise,
  };
};
